@import '../../../../scss/theme-bootstrap';

.product-brief__price,
.product-sku-price,
.esearch-product__price {
  .original-price {
    text-decoration: line-through;
  }
  .sale-price {
    color: $color-red;
  }
}
